<template>
	<div class="voice" ref="main">
		<div class="voice-top">
			<div class="voice-title">
				<div class="title">二郎查配音下单工具</div>
				<div class="anniu"><a href="#list"><el-button type="primary">历史订单</el-button></a></div>
				<div class="anniu"><a href="https://www.erlangcha.com/htmlText/40" target="_blank" rel="noopener noreferrer"> <el-button type="warning">配音案例展示</el-button></a></div>
				<div class="anniu" style="color:#666;font-weight: 400;">{{trichText}}</div>
			</div>
			<div class="voice-prompt">
				<!-- <div class="text">配音工作时间：周一到周五9:30 - 12:30  14:00 - 18:30</div> -->
			</div>
		</div>
		<div class="gongdan">
			<!-- <div class="dan">
				<div class="text" style="text-align:center;padding:5px;background:#fff3bb;">
					<span><i class="el-icon-s-comment"></i>通知：</span>二郎查配音放假时间1月27日-2月8日，有配音需要请提前安排，放假期间不接单
				</div>
			</div> -->
			<div class="dan">
				<div class="text" style="text-align:center;padding:5px;background:#fff3bb;">
					<span><i class="el-icon-s-comment"></i>活动通知：</span>介绍配音师成功后送1000积分
					<a style="margin-left:20px;cursor: pointer;" @click="dialogPratic = true">点击参加</a>
				</div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>老师选择:</div>
				<div class="inputbox">
					<el-select v-model="mixer_id" placeholder="请选择配音老师">
						<el-option class="inputbox-tertch" v-for="item in mixerlist" :key="item.id" :label="item.mixer + (item.status == 2 ? '（暂停接单，待配音师归来可下单）' : '')" :value="item.id" :disabled="item.status == 2">{{item.mixer + (item.status == 2 ? '（暂停接单，待配音师归来可下单）' : '')}}</el-option>
						<!-- <el-option class="inputbox-tertch" v-for="item in mixerlist" :key="item.id" :label="item.mixer + (item.status == 2 ? '（暂停接单，待配音师归来可下单）' : '') + '（' + item.level + '星配音师）'" :value="item.id" :disabled="item.status == 2">{{item.mixer + (item.status == 2 ? '（暂停接单，待配音师归来可下单）' : '')}}<i class='el-icon-star-on' style="color:#f00" v-for="(items,keys) in item.level" :key='keys'></i>{{`在线时间：${item.online_time}`}}</el-option> -->
					</el-select>
					<!-- <div class="xiaohong">预计2小时内完成</div> -->
				</div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>配音风格:</div>
				<div class="inputbox">
					<el-select v-model="pyCreatde" placeholder="请选择配音风格">
						<el-option v-for="item in pyCreatdeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</div>
			</div>
			<div class="dan">
				<div class="text">配音时长:</div>
				<div class="inputbox"><el-input v-model="duration" placeholder="请填写配音时长"></el-input></div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>配音文字:</div>
				<div class="inputbox">
					<el-input type="textarea" :rows="8"  maxlength="1000" v-model="content" @input="onContentChange" placeholder="请填写配音文字"></el-input>
					<div class="xiaohong">已输入{{content_word_count}}个字符，<span class="jiage" v-if="payType == 1">需￥{{contentMoney}}</span><span class="jiage" v-if="payType == 2">需{{contentintegral}}积分</span></div>
				</div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>配音完成时间：</div>
				<div class="inputbox">
					<el-select v-model="arriveTime" value-key="id" placeholder="请选择配音完成时间" >
						<el-option
						v-for="(item,key) in timer_list"
						:key="key"
						:label="item.show_time"
						:value="item">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="dan">
				<div class="text">备注:</div>
				<div class="inputbox">
					<el-input type="textarea" :rows="3" maxlength="200" @input="onRemarkChange" v-model="remark" placeholder="请具体描述问题情况"></el-input>
					<div class="xiaohong">{{Remarknum}}/200</div>
				</div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>付费方式:</div>
				<div class="inputbox">
					<el-radio v-model="payType" :label="1">在线支付</el-radio>
  					<el-radio v-model="payType" :label="2">积分支付</el-radio>
				</div>
			</div>
			<div class="dan">
				<div class="text">上传案例:</div>
				<div class="inputbox">
					<!-- <el-upload class="upload-demo" drag :action="origin + '/voice/dub/uploadVideoProductImg?token=' + token" :limit="1"  -->
					<!-- <el-upload class="upload-demo" drag :action="'https://user.erlangcha.com/voices/dub/uploadDubVideoCase?token=' + token" :limit="1"  -->
					<el-upload class="upload-demo" drag :action="$api.uploadDubVideoCase + '?token=' + token" :limit="1" 
						ref="upload"
						accept=".mp4,.m4a,.mp3,"
						show-file-listapi
						:file-list="fileListUpload"
						:on-exceed="onExceedtoupload"
						:on-remove="onRemoveupload"
						:on-success="onSuccessupload"
						:on-change="onChangeUpload"
						:before-upload="beforeAvatarUpload">
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						<div class="el-upload__tip" slot="tip">最多可传 1 个，扩展名为：.mp4,.m4a,.mp3,</div>
					</el-upload>
				</div>
			</div>
			<div class="enderQueren">
				<el-button type="primary" @click="onSubmit" :disabled="delenteAnniu">确认提交</el-button>
				<el-button type="info" @click="onChongxin" :disabled="delenteAnniu">重新填写</el-button>
			</div>
		</div>
		<el-dialog title="详情请加微信" :visible.sync="dialogPratic" width="500px">
			<span><img src="https://erlangcha.oss-cn-beijing.aliyuncs.com/indeximg/baixueying.jpg" alt=""></span>
		</el-dialog>
		<div id="list"><VoiceList ref="VoiceList"></VoiceList></div>
	</div>
</template>

<script>
import VoiceList from './voiceList.vue'
export default {
	data () {
		return {
			token:JSON.parse(localStorage.getItem('TZ-USER')).token,
			mixer_id:'',//配音老师id
			mixerlist:[],//配音老师列表
			pyCreatde:1,//配音师风格
			pyCreatdeList:[],//配音师风格列表
			duration:'',//配音时长
			content:'',//配音文字
			content_word_count:'0',//配音文字字数
			contentMoney:'0',//配音文字价格
			remark:'',//备注
			Remarknum:'0',//备注字数
			delenteAnniu:false,
			payType:1,//付费方式
			// payTypeList:[
			// 	{
			// 		name:'在线支付',
			// 		value:1,
			// 	},{
			// 		name:'积分支付',
			// 		value:2,
			// 	}
			// ],//付费方式
			video_case:'',//配音案例
			attachment_id:'',//上传文件只取ID
			fileListUpload:[],//上传的文件列表
			origin:window.location.origin,
			contentintegral:0,
			timer_list:[],
			arriveTime:{},//配音完成时间
			trichText:"",
			dialogPratic:false,
		}
	},

	components:{
		VoiceList
	},

	mounted(){
		if(this._isMobile && this._isMicrom){alert('请用微信打开，否则不能在线支付')}
		this.onGongzuo()
		this.ongetMixerList()
		this.onpcGetMixerStyle()
		this.ongetAppointTimer()
		this.ongetDubVerbalTrick();
		if(this.$route.hash){
			setTimeout(() => {
				this.$nextTick(() => {
					window.location.href = this.$route.fullPath
				})
			}, 1000);
		}
	},
	
	methods: {
		//配音随机一条提示
		ongetDubVerbalTrick(){
			var param = {
				type:1,
			}
			this.$service.get(this.$api.getDubVerbalTrick,param, (res)=> {
				if(res.code == '200'){
					this.trichText = res.data
				}
			})
		},
		//获取配音完成时间
		ongetAppointTimer(){
			var prame = {
				token:this.token
			}
			this.$service.get(this.$api.dubOverTime,prame, (res)=> {
				if(res.code == '200'){
					res.data.timer_list.forEach((v,k) => {
						v.id = k+1
					});
					this.timer_list = res.data.timer_list
					// this.arriveTime = res.data.timer_list[0]
					// console.log(this.timer_list,123)
				}
			})
		},
		// 配音风格
		onpcGetMixerStyle(){
			this.$service.get(this.$api.pcGetMixerStyle,{}, (res)=> {
				if(res.code == '200'){
					this.pyCreatdeList = res.data
				}
			})
		},
		// 配音师接口
		ongetMixerList(){
			this.$service.get(this.$api.getMixerList,{}, (res)=> {
				if(res.code == '200'){
					this.mixerlist = res.data
				}
			})
		},
		//提交
		onSubmit(){
			if(this.mixer_id == ''){this.$message({message: '请选择配音师', type: 'warning'}); return}
			if(this.pyCreatde == ''){this.$message({message: '请选择配音风格', type: 'warning'}); return}
			if(this.content == ''){this.$message({message: '请填写配音文字', type: 'warning'}); return}
			if(!this.arriveTime.end_time){this.$message({message: '请选择配音完成时间', type: 'warning'}); return}
			this.delenteAnniu = true
			var prame = {
				mixer_id:this.mixer_id,//配音老师id
				style_id:this.pyCreatde,//配音师风格
				duration:this.duration,//配音时长
				content:this.content,//配音文字
				content_word_count:this.content_word_count,//配音文字字数
				start_time:this.arriveTime.start_time,//配音完成时间
				end_time:this.arriveTime.end_time,//配音完成时间
				remark:this.remark,//备注
				payment_method:this.payType,
				video_case:this.attachment_id//案例配音
			}
			this.$service.post(this.$api.pcUpdateDub,prame, (res)=> {
				if(res.code == '200'){
					if(this._isMobile){
						this.$router.push('/voice/voicemerkorder?order_num=' + res.data.order_id + '&payment_method=' + this.payType)
					}else{
						if(this.payType == 1){
							this.onvoiceOrderPay(res.data.order_id)
						}else{
							this.$router.push('/voice/voicemerkorder?order_num=' + res.data.order_id + '&payment_method=' + this.payType)
						}
					}
				}
			})
			this.delenteAnniu = false
		},
		//提交前调用支付接口
		onvoiceOrderPay(order_id){
			var param = {
				order_num:order_id,
			}
			this.$service.post(this.$api.pcDubOrderPay,param, (res)=> {
				if(res.code == '200'){
					this.$router.push('/voice/voicemerkorder?order_num=' + res.data.order_num + '&payment_method=' + this.payType)
				}
			})
		},	
		//重写
		onChongxin(){
			this.mixer_id = '',//配音老师id
			this.pyCreatde = 1,//配音师风格
			this.duration = '',//配音时长
			this.content = '',//配音文字
			this.content_word_count = '0',//配音文字字数
			this.contentMoney = '0',//配音文字价格
			this.remark = ''//备注
		},
		// 删除标点符号
		onStrReplace(str){
		 	return str.replace(/[\ |\~|\`|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\_|\+|\=|\||\\|\[|\]|\{|\}|\;|\:|\"|\'|\,|\<|\.|\>|\/|\?|\，|\。|\？|\：|\、|\‘|\’|\“|\”|\；|\【|\】|\「|\」|\、|]/g,"")
		},
		//配音文字监听
		onContentChange(){
			if(this.content.length <= 1000){
				this.content_word_count = this.onStrReplace(this.content).length
				this.onpcGetHowMoney()
			}else{
				this.$message({message: '配音文字最多1000字', type: 'warning'})
			}
			
		},
		// 配音价格
		onpcGetHowMoney(){
			if(this.mixer_id == ''){this.$message({message: '请选择配音师', type: 'warning'}); return}
			var prame = {
				count:this.content_word_count,
				mixer_id:this.mixer_id,
			}
			this.$service.get(this.$api.pcGetHowMoney,prame, (res)=> {
				if(res.code == '200'){
					this.contentMoney = res.data.money
					this.contentintegral = res.data.integral
					if(this.content_word_count == 0){
						this.contentMoney = 0
					}
				}
			})
			
		},
		onRemarkChange(){
			if(this.remark.length <= 200){
				this.Remarknum = this.onStrReplace(this.remark).length
			}else{
				this.$message({message: '备注超过字数', type: 'warning'})
			}
		},
		//跳转列表
		voiceList(){
			this.$router.push('/voice/voiceList')
		},
		
		onGongzuo(){
			this.$service.get(this.$api.getNowTime,{}, (res)=> {
				if(res.code == '200'){
					if(res.data.time){
						this.$alert(res.data.text, '提示', {
							confirmButtonText: '确定',
						});
					}
				}
			})
		},
		//文件上传成功，失败 所有都会调用
		onChangeUpload(){
			this.delenteAnniu = false
		},
		//文件上传超出
		onExceedtoupload(){
			this.$message({message: '上传文件已超出1个', type: 'warning'});
		},
		//文件上传成功
		onSuccessupload(res, file,fileList){
			if(res.code == 200){
				this.fileListUpload = fileList
				this.attachment_id = res.data
			}else{
				this.$refs.upload.uploadFiles.pop()//上传失败后移出刚上传的文件
				this.$message.error(res.msg);
				// console.log(this.$refs.upload.uploadFiles)
			}
			
		},
		//上传文件移出
		onRemoveupload(file, fileList){
			this.fileListUpload = fileList
		},
		//文件上传控制大小
		beforeAvatarUpload(file) {
			const isLt2M = file.size / 1024 / 1024 < 20;
			if (!isLt2M) {
				this.$message.error('上传文件大小不能超过 20MB!');
			}
			return isLt2M;
		}
	}
}
</script>

<style lang="scss" scoped>
.voice{
	padding-bottom: 20px;
	
	.voice-top{
		border-bottom: 1px solid #ededed;
		padding-bottom: 5px;
		.voice-title{
			display: flex;
			font-weight: 500;
			padding-bottom: 15px;
			align-items: center;
			.title{
				font-size: 22px;
			}
			.anniu{
				margin-left: 10px;
			}
		}
		.voice-prompt{
			display: flex;
			justify-content: space-between;
			.horn{
				padding: 5px 10px;
				background: #fefae9;
				color: #ff9a56;
			}
		}
	}
	.gongdan{
		margin-top: 20px;
		display: block;
		.jiage{
		}
	}
}
@media screen and (max-width: 750px) {
	.inputbox-tertch{
		max-width: 300px!important;
		left: 0 !important;
	}
	.voice{
		.voice-top{
			.voice-title{
				display: block;
				.anniu{
					width: 2rem;
					display: inline-block;
					margin-left: 0;
					margin-right: 10px;
					margin-top: 10px;
				}
			}
			.voice-prompt{
				display: block;
				.horn{
					margin-bottom: 10px;
				}
				.text{
					margin-bottom: 10px;
				}
			}
		}
		.gongdan{
			.dan{
				&.chang .text{ width: 3rem;}
				&.chang .inputbox{ width: 3rem;}
				.text{
					width: 1.8rem;
				}
				.inputbox{
					width: 5.7rem;
				}
			}
			.enderQueren{
				padding-bottom: .5rem;
				padding-top: 0;
				margin: 0 auto;
			}
		}
	}
}
</style>
<style lang="scss">
.voice{
	.el-upload-dragger{
		height: 100px;
	}
	.el-upload-dragger .el-icon-upload{
		margin-top: 3px;
	}
}
</style>