<template>
	<div class="tofilmList">
		<div class="tofilm-top">
			<div class="tofilm-title">
				<div class="title">配音历史订单<span style="color:#f00" v-if="_isMobile">(往左滑动查看订单详情)</span></div>
			</div>
			<div class="tofilm-prompt">
			</div>
		</div>
		<div class="listtable">
			<el-table border :data="tableData" v-loading="remandLoading" style="width: 100%" empty-text="暂无数据">
				<el-table-column prop="order_id" label="订单号"></el-table-column>
				<el-table-column prop="mixer" label="配音师"></el-table-column>
				<el-table-column prop="status" label="订单状态">
					<template slot-scope="scope">
						<div style="color:#f00" v-if="scope.row.status == 1">未付款</div>
						<div v-else :style="'color:' + (scope.row.status == 2 ? '#2251db' : scope.row.status == 3 ? '#2251db' : scope.row.status == 4 ? '#67c23a' : scope.row.status == 5 ? '#333' : scope.row.status == 6 ? '#333' : '')">{{scope.row.status == 2 ? '配音中' : scope.row.status == 3 ? '配音中' : scope.row.status == 4 ? '已完成' : scope.row.status == 5 ? '已退款' : scope.row.status == 6 ? '订单失效' : ''}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="status" label="支付方式">
					<template slot-scope="scope">
						{{scope.row.payment_method == 1 ? '在线支付' : '积分支付'}}
					</template>
				</el-table-column>
				<el-table-column prop="cost" label="订单金额/积分">
					<template slot-scope="scope">
						<span style="color:#f00" v-if="scope.row.payment_method == 1">{{scope.row.cost}}元</span>
						<span style="color:#67C23A" v-else>{{scope.row.integral}}积分</span>
					</template>
				</el-table-column>
				<el-table-column prop="created_at" label="下单时间"></el-table-column>
				<el-table-column prop="date" label="操作" width="150">
					<template slot-scope="scope">
						<div class="center"><el-button type="primary" @click="onOrderxq(scope.row)">订单详情</el-button></div>
						<div class="center" style="margin-top:10px" v-if="scope.row.file_url">
							<a target="_blank" :href="scope.row.file_url">
								<el-button type="success">下载配音</el-button>
							</a>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="lv-page"  :page-size="per_page" :pager-count="11"  @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page></el-pagination>
		</div>
		<el-dialog custom-class="orderdetali" title="订单详情"	:visible.sync="orderdetalis" width="400px" >
			<div class="detalis">

			</div>
		</el-dialog>
		<!-- 支付 -->
		<Payorder ref="Payorder"></Payorder>
	</div>
</template>

<script>
import Payorder from '../tofilm/payorder.vue'
export default {
	data () {
		return {
			tableData:[],//订单列表
			per_page:10,//一页多少条
			lvtotal:null,//共几条数据
			remandLoading:false,//loading加载
			orderdetalis:false,//订单详情弹窗
		}
	},
	components:{
		Payorder,
	},
	mounted(){
		this.ongetVideoOrderList(1)
	},

	methods: {
		// 配音订单列表
		ongetVideoOrderList(val){
			var param = {
				current_page:val,
				limit:this.per_page,
			}
			this.remandLoading = true
			this.$service.post(this.$api.pcGetDubOrderList,param, (res)=> {
				if(res.code == '200'){
					this.tableData = res.data.list
					this.lvtotal = res.data.count
				}
			})
			this.remandLoading = false
		},
		//翻页
		DialogCurrentChange(val){
			this.ongetVideoOrderList(val)
		},
		//订单详情
		onOrderxq(row){
			// console.log(row)
			this.$router.push('/voice/voiceinfo?order_num=' + row.order_id)
			// this.orderdetalis = true
		},
	}
}
</script>

<style lang="scss" scoped>
.tofilmList{
	.tofilm-top{
		border-bottom: 1px solid #ededed;
		padding-bottom: 5px;
		.tofilm-title{
			display: flex;
			font-weight: 500;
			padding-bottom: 15px;
			.title{
				font-size: 22px;
			}
		}
		.tofilm-prompt{
			display: flex;
			justify-content: space-between;
			.horn{
				padding: 5px 10px;
				background: #fefae9;
				color: #ff9a56;
			}
		}
	}
	.listtable{
		margin-top: 20px;
		
	}
}
@media screen and (max-width: 750px) {
	.tofilmList{
		.tofilm-top{
			.tofilm-prompt{
				display: block;
				.text{
					margin-bottom: 10px;
				}
			}
		}
		.gongdan{
			.dan{
				&.chang .text{ width: 3rem;}
				&.chang .inputbox{ width: 3rem;}
				.text{
					width: 1.8rem;
				}
				.inputbox{
					width: 5.7rem;
				}
			}
			.enderQueren{
				padding-bottom: .5rem;
				padding-top: 0;
				margin: 0 auto;
			}
		}
	}
}
</style>
<style  lang="scss">
.tofilmList{
	.listtable{
		.cell{
			text-align: center;
		}
	}
}
</style>
